<template>
<div v-if="images.length > 0" class="cart__item-img">
  <img v-for="(image, index) in images" :key="index" :src="$filters.image(image.path)">
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
export default {
  props: {
    images: {
      type: Array,
      default: null
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    }
  },
  setup (props) {
    const imageDimensions = computed(() => {
      let str = ''
      if (props.width) {
        str = Number.parseInt(props.width)
      }
      str += 'x'
      if (props.height) {
        str += Number.parseInt(props.height)
      }
      return str
    })
    return {
      imageDimensions
    }
  }
}
</script>
